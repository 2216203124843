import SkillBar from 'react-skillbars';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      triggerOnce: true
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      triggerOnce: true
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      triggerOnce: true
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      triggerOnce: true
    }
  };

  const skillsList = [
    { type: 'Java', level: 80 },
    { type: 'Python', level: 70 },
    { type: 'Javascript', level: 70 },
    { type: 'C', level: 60 },
    { type: 'NoSQL', level: 50 },
    { type: 'HTML', level: 40 },
    { type: 'CSS', level: 40 },
    { type: 'Flutter', level: 40 },
    { type: 'React', level: 20 },
    
  ];
  const colors = {
    bar: "#BF40BF",
    title: {
      text: "#fff",
      background: "#702963"
    }
  };
  

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>My Skills</h2>
                        <p>I am currently working on improving my skills in React and C# in Unity<br></br></p>
                        <SkillBar skills={skillsList} colors={colors} animationDuration={1000}/>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  )
}
