import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Fuel-Line-Car.png";
import projImg2 from "../assets/img/line-skip.png";
import projImg3 from "../assets/img/Ptery-Fight.png";
import colorSharp2 from "../assets/img/placeholder.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      
      title: "Fuel-Line",
      description: "Mobile-First Web App using MERN stack to help users choose navigation routes based on fuel prices." + 
      "Created in collaboration with Gary Mo, Brad masciotra, and Ethan Johnston",
      imgUrl: projImg1,
      projectLink: 'https://fuel-line.fly.dev/'
    },
    {
      title: "LineSkip",
      description: "A website that allows users to view and interact with restaurant Queues." + 
      "Created in collaboration with Gary Mo and Adam Chen",
      imgUrl: projImg2,
      projectLink: 'https://delicate-pastelito-673797.netlify.app/'
    },
    {
      title: "Ptery Fight",
      description: "Two-Player Co-op Java Game - created in collaboration with Rhys Mahannah",
      imgUrl: projImg3,
      projectLink: 'https://github.com/carcango/2522-202230-Term-Project-Ptery-Fight'
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Here are some of my recent projects</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt='background right'></img>
    </section>
  )
}
